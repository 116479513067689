/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef } from 'react';
import IconSearch from 'assets/images/icons/search.svg';
import IconClear from 'assets/images/icons/clear.svg';
import useDebounce from 'shared/hooks/useDebounce';

const SearchBox = ({
  onChange,
  search,
  setSearch,
  className,
  onFocus = () => {},
  autoFocus = false,
  disabled,
  debounceTime = 500
}) => {
  const debouncedSearch = useDebounce(search, debounceTime);
  const isFirstRun = useRef(true);

  const searchSubmit = e => {
    e.preventDefault();
    if (onChange) onChange();
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    onChange();
    //eslint-disable-next-line
  }, [debouncedSearch]);

  return (
    <form
      onSubmit={searchSubmit}
      className={`pr-0 search-box d-flex align-items-center ${className || 'col-md-5 col-12'}`}
    >
      <img src={IconSearch} alt='search' className='mr-2' />
      <input
        type='text'
        value={search}
        onChange={({ target }) => setSearch(target.value)}
        placeholder='Search'
        className='py-1 flex-fill'
        onFocus={onFocus}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      {search && (
        <img
          src={IconClear}
          alt='search'
          className='clear-search pointer'
          onClick={() => setSearch('')}
        />
      )}
    </form>
  );
};

export default SearchBox;

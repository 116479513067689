import React from 'react';
import Modal from 'shared/components/modals/Modal';
import Pricing from './Pricing';

const MediaPricingModal = ({ onClose, media, title, item, type, onSuccess }) => {
  const endpoints = {
    season: `/tv-shows/${media.id}/seasons/${item.id}/monetization`,
    episode: `/tv-shows/${media.id}/episodes/${item.id}/monetization`,
  }

  return (
    <Modal
      onClose={onClose}
      size='lg'
      className='mx-2 mt-2 modal-media d-flex flex-column'
      parentClassName='modal-media-wrapper'
    >
      <div className='modal-title bg-purple__light row px-4 py-3 mb-6'>
        <div className='text-primary__light'>{title}</div>
      </div>
      <div>
        <Pricing
          monetization={item.monetization}
          endpoint={endpoints[type]}
          mediaId={media.id}
          isOnModal={true}
          onSuccess={onSuccess}
          disableTVOD={true}
        />
      </div>
    </Modal>
  );
};

export default MediaPricingModal;

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { formatDate, getSuggestetMax, modifyChartVerticalAxis } from 'utils/analyticsHelpers';
import { bindDurationHours, formatNumber } from 'utils/appHelpers';

const BarChart = ({ data, biggestValue, unit, chartOptions = {}, dataSetOptions = {} }) => {
  if (!data) return null;

  const isPricing = unit === '$';
  const isWatchtime = unit === 'mins';

  const options = {
    scales: {
      y: {
        suggestedMax: getSuggestetMax(biggestValue),
        ticks: {
          beginAtZero: true,
          callback: value => modifyChartVerticalAxis(value, isWatchtime, isPricing),
        },
      },
      x: {
        ticks: {
          autoSkipPadding: 20,
          callback: index => formatDate(data[index].date, 'DD/MM'),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          title: ([tooltipItem]) => {
            let value = tooltipItem.raw;
            if (isWatchtime) value = bindDurationHours(+value);
            return isPricing
              ? `${unit}${formatNumber(value)}`
              : isWatchtime
              ? value
              : `${formatNumber(value)} ${unit}`;
          },
          label: ({ label }) => formatDate(label, 'MMM DD, YYYY'),
        },
      },
      datalabels: {
        display: false,
      },
    },
    ...chartOptions,
  };

  const chartData = ctx => {
    const bar_ctx = ctx.getContext('2d');
    const purple_orange_gradient = bar_ctx.createLinearGradient(0, 0, 0, 600);
    purple_orange_gradient.addColorStop(0, '#ACA2FF');
    purple_orange_gradient.addColorStop(0.75, '#DBD7FF');

    const result = {
      labels: [],
      datasets: [
        {
          fill: 'start',
          backgroundColor: purple_orange_gradient,
          hoverBackgroundColor: purple_orange_gradient,
          data: [],
          maxBarThickness: 40,
          minBarLength: 5,
          ...dataSetOptions,
        },
      ],
    };

    data.forEach(({ date, value }) => {
      result.labels.push(formatDate(date));
      result.datasets[0].data.push(value);
    });

    return result;
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;

import React from 'react';
import { planPeriods, currencies } from 'configs';
import InputGroup from 'shared/components/forms/InputGroup';
import SelectGroup from 'shared/components/forms/SelectGroup';
import TextAreaGroup from 'shared/components/forms/TextAreaGroup';
import CreateButton from 'shared/components/CreateButton';

const armCurrency = currencies.filter(i => i.value === 'AMD');

const InitialForm = ({ form, isSubscription, onFormChange, onOpenForm }) => {
  const numberOfCountries = form.product_country_based_price_amd
    ? Object.values(form.product_country_based_price_amd).length
    : 0;

  const disabled =
    form.apple_identifier &&
    form.description &&
    form.google_identifier &&
    form.name &&
    form.price &&
    form.price_amd &&
    form.price_rub &&
    form.stripe_identifier;

  return (
    <div className='mh-585'>
      <InputGroup
        type='text'
        name='name'
        value={form.name}
        placeholder={'Name'}
        outLabel='Title'
        containerClass='d-flex align-items-center justify-content-between'
        onChange={onFormChange}
        className='col-7 p-0'
        required
      />
      <TextAreaGroup
        name='description'
        value={form.description}
        placeholder='Description'
        outLabel='Description'
        outSubLabel='Nulla molestie, dui non euismod vulputate, mauris urna iaculis sem, id varius odio elit.'
        containerClass='d-flex justify-content-between'
        className='col-7 p-0'
        onChange={onFormChange}
        required
      />
      <InputGroup
        type='number'
        name='price'
        value={form.price}
        placeholder={'Enter price'}
        outLabel='Price'
        containerClass='d-flex align-items-center justify-content-between'
        onChange={onFormChange}
        className='col-7 p-0'
        max={999}
        required
      />
      {isSubscription && (
        <SelectGroup
          name='period'
          value={planPeriods.find(i => i.value === form.period)}
          placeholder={'Select Period'}
          outLabel='Period'
          containerClass='d-flex align-items-center justify-content-between'
          onChange={item => onFormChange({ target: { name: 'period', value: item.value } })}
          className='col-7 p-0'
          options={planPeriods}
          required
        />
      )}
      <hr />
      <div className='mt-3'>
        <h6>Platforms</h6>
        <p className='text-secondary col-7 p-0 small'>
          Nulla molestie, dui non euismod vulputate, mauris urna iaculis sem, id varius odio elit ut
          massa.
        </p>
      </div>
      <InputGroup
        type='text'
        name='google_identifier'
        value={form.google_identifier}
        placeholder={'Enter platform code'}
        outLabel='Google'
        containerClass='d-flex align-items-center justify-content-between'
        onChange={onFormChange}
        className='col-7 p-0 mt-2'
        required
      />
      <InputGroup
        type='text'
        name='apple_identifier'
        value={form.apple_identifier}
        placeholder={'Enter platform code'}
        outLabel='Apple'
        containerClass='d-flex align-items-center justify-content-between'
        onChange={onFormChange}
        className='col-7 p-0'
        required
      />
      <InputGroup
        type='text'
        name='stripe_identifier'
        value={form.stripe_identifier}
        placeholder={'Enter platform code'}
        outLabel='Stripe'
        containerClass='d-flex align-items-center justify-content-between'
        onChange={onFormChange}
        className='col-7 p-0'
        required
      />
      <InputGroup
        type='text'
        name='price_rub'
        value={form.price_rub}
        placeholder={'Enter Yandex price'}
        outLabel='Yandex'
        containerClass='d-flex align-items-center justify-content-between'
        onChange={onFormChange}
        className='col-7 p-0'
        required
      />
      <div className='d-flex align-items-center justify-content-between mb-5'>
        <label className='fw-500 mb-0'>Ameria</label>
        <div className='col-7 p-0'>
          <div className='row align-items-center'>
            <div className='col-7'>
              <div className='form-field-with-dropdown'>
                <InputGroup
                  type='number'
                  name='price_amd'
                  value={form.price_amd}
                  onChange={onFormChange}
                  min={1}
                  max={9999999}
                  required
                  containerClass='mb-0'
                />
                <SelectGroup
                  value={armCurrency[0]}
                  onChange={() => {}}
                  options={armCurrency}
                  menuPlacement='top'
                  disabled
                  containerClass='mb-0'
                />
              </div>
            </div>
            <div className='col-4 pl-0 ameria'>
              <CreateButton
                type='button'
                name={`Country-Base (${numberOfCountries})`}
                onClick={onOpenForm}
                size='sm'
                className='text-nowrap border-0 p-sm-0 newCountry'
                disabled={!disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialForm;

import React from 'react';
import Pricing from './MediaPricingModal/Pricing';

const PricingTab = ({ media = {}, getMovie, isOnModal }) => {
  return (
    <Pricing
      monetization={media.monetization}
      endpoint={`/medias/${media.id}/monetization`}
      mediaId={media.id}
      isOnModal={false}
      onSuccess={getMovie}
    />
  );
};

export default PricingTab;

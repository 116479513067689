import React from 'react';
import IconEdit from 'assets/images/icons/edit.svg';
import IconDelete from 'assets/images/icons/delete.svg';
import IconRefresh from 'assets/images/icons/refresh.svg';
import IconCategories from 'assets/images/icons/categories.svg';

const ActionButton = ({ onClick, type = 'remove', className, disabled, src, icon }) => {
  const types = {
    remove: IconDelete,
    edit: IconEdit,
    refresh: IconRefresh,
    view: IconCategories,
    audio: IconCategories
  };
  return (
    <button
      type='button'
      className={`btn btn-sm btn-icon ${className || ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon ? icon : <img src={src || types[type]} alt='action' />}
    </button>
  );
};

export default ActionButton;

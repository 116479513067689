import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Header from 'shared/components/layouts/Header';
import { Api } from 'utils/connectors';
import { useSelector, useDispatch } from 'react-redux';
import { onTVShowItemGet } from 'app/Main/actions';
import LangToggle from 'shared/components/LangToggle';
import Loading from 'shared/components/loadings/Loading';
import { getError, confirmBox, getInitialChannel } from 'utils/appHelpers';
import MediaManageForm from 'shared/components/MediaManageForm';
import TabsButtons from 'shared/components/layouts/TabsButtons';
import ImagesTab from './ImagesTab';
import SeasonsTab from './SeasonsTab';
import EpisodesTab from './EpisodesTab';
import RelatedTab from './RelatedTab';
import BackButton from 'shared/components/BackButton';
import TrailerTab from './TrailerTab';
import GeoBlockingTab from './GeoBlockingTab';
import SEOTab from './SEOTab';
import CastAndCrewTab from './CastAndCrewTab';
import PricingTab from './PricingTab';

const tabs = [
  { name: 'Episodes', key: 'episodes', component: EpisodesTab },
  { name: 'Seasons', key: 'seasons', component: SeasonsTab },
  { name: 'Images', key: 'images', component: ImagesTab },
  { name: 'Trailer', key: 'trailer', component: TrailerTab },
  { name: 'Related', key: 'related', access: 'media_related', component: RelatedTab },
  { name: 'Geo Blocking', key: 'geo-blocking', component: GeoBlockingTab },
  { name: 'SEO', key: 'seo', component: SEOTab },
  {
    name: 'Cast & Crew',
    key: 'cast-crew',
    access: 'cast_and_crew_view',
    component: CastAndCrewTab,
  },
  { name: 'Pricing', key: 'pricing', access: 'movie_pricing', component: PricingTab },
];

const TVShowsManage = ({ match, history }) => {
  const [fetching, setFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [tab, setTab] = useState('episodes');
  const { tvshowsItems: moviesItems, channels, account } = useSelector(state => state);
  const movieId = match.params.id;
  const isEdit = !!movieId;
  const [lang, setLang] = useState('en');
  const [formData, setFormData] = useState();

  const isChannelAdmin = account.role.includes('CHANNEL');

  const setFormInitialData = (movie = {}) => {
    setFormData({
      title: movie.title || '',
      description: movie.description || '',
      allowed_age: movie.allowed_age || '',
      secondary_info: movie.secondary_info || '',
      year: movie.year || '',
      published: movie.published || false,
      tags: movie.tags || [],
      genres: movie.genres || [],
      geo_blocked_locations: movie.geo_blocked_locations || [],
      og_title: movie.og_title || '',
      og_description: movie.og_description || '',
      og_url: movie.og_url || '',
      channel:
        isEdit || isChannelAdmin
          ? channels[lang] && getInitialChannel(channels[lang].content, movie.channel_id)
          : null,
    });
  };

  const initMovieManager = () => {
    if (isEdit) {
      const storeMovie = moviesItems[movieId] && moviesItems[movieId][lang];
      if (storeMovie) setFormInitialData(storeMovie);
      getMovie();
    } else setFormInitialData();
  };

  const updateMovieStore = data => {
    const movieItem = { [data.id]: { ...moviesItems[data.id], [lang]: data } };
    dispatch(onTVShowItemGet({ ...moviesItems, ...movieItem }));
    setFormInitialData(data);
  };

  const modifyResponse = data => {
    data.og_title = data.og_title || data.title;
    return data;
  };

  const getMovie = async () => {
    try {
      setFetching(true);
      const { data } = await Api.get(`/medias/${movieId}?locale=${lang}`);
      data.tags = data.tags?.map(i => ({ value: i.id, label: i.name, id: i.id })) ?? [];
      updateMovieStore(modifyResponse(data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    if (isEdit) {
      updateMovieStore({ ...moviesItems[movieId][lang], [name]: value });
    }
  };

  const removeItem = async () => {
    try {
      await Api.delete(`/tv-shows/${movieId}`);
      enqueueSnackbar('Successfully deleted', { variant: 'success' });
      history.push('/tv-shows');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = () => {
    const options = {
      onSuccess: removeItem,
      message: `Are you sure you want to delete the movie?`,
      confirm: 'Delete',
    };
    confirmBox(options);
  };

  const createItem = async body => {
    try {
      setFetching(true);
      const res = await Api.post(`/tv-shows?locale=${lang}`, body);
      setFetching(false);
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push(`/tv-shows/${res.data.id}`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const updateItem = async body => {
    try {
      setFetching(true);
      await Api.put(`/tv-shows/${movieId}?locale=${lang}`, body);
      setFetching(false);
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onFormSubmit = e => {
    e && e.preventDefault();
    const body = {
      ...formData,
      channel_id: formData.channel.id ?? null,
      channel: undefined,
    };
    body.tags = body.tags ? body.tags.map(i => ({ id: i.id })) : [];
    isEdit ? updateItem(body) : createItem(body);
  };

  const onBackRoute = () => {
    window.localStorage.setItem('isBackNavigation', true);
  };

  useEffect(() => {
    initMovieManager();
    //eslint-disable-next-line
  }, [movieId, lang, channels]);

  if (!formData) return <Loading className='w-100 mt-5' />;

  const props = {
    media: moviesItems[movieId] && moviesItems[movieId][lang],
    updateMovieStore: updateMovieStore,
    updateMovie: initMovieManager,
    onSubmit: onFormSubmit,
    fetching,
    lang,
    setLang,
    getMovie,
    type: 'TV_SHOW',
  };

  const modifiedTabs = tabs.map(tab => ({
    ...tab,
    ...(tab.key === 'geo-blocking' && {
      badgeValue: moviesItems[movieId]?.[lang]?.geo_blocked_locations?.length,
    }),
  }));

  const TabContent = tabs.find(({ key }) => key === tab).component;

  return (
    <>
      <Header>
        <div className='d-flex align-items-center'>{isEdit ? 'Details' : 'Create new movie'}</div>
      </Header>
      <main className='main-content'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <BackButton onClick={onBackRoute} />
          <LangToggle lang={lang} setLang={setLang} disabled={fetching} />
        </div>
        <div className='row'>
          <div className='col-12 col-md-5'>
            <MediaManageForm
              handleChange={handleChange}
              formData={formData}
              setFormData={setFormData}
              updateMovieStore={updateMovieStore}
              onSubmitForm={onFormSubmit}
              lang={lang}
              isEdit={isEdit}
              onDelete={onItemRemove}
              fetching={fetching}
              media={moviesItems[movieId] && moviesItems[movieId][lang]}
              type='tv_show'
            />
          </div>
          <div className='col-12 col-md-7 mt-3 mt-md-0'>
            <div className='px-3 pb-2 bg-white'>
              <div className='bg-purple__light row px-3 py-2 d-flex align-items-center justify-content-between'>
                <div className='text-primary__light'>Media</div>
                <div className='d-flex align-items-center'>
                  <TabsButtons
                    setTab={setTab}
                    tabs={modifiedTabs}
                    tab={tab}
                    disabled={!props.media}
                  />
                </div>
              </div>
              {props.media ? (
                <TabContent {...props} />
              ) : (
                <div className='mt-3 mb-1 text-primary__light'>Please create movie first</div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TVShowsManage;

import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSelector } from 'react-redux';
import { PermissionService } from 'services/PermissionService';
import { allLanguages } from 'configs';
import MediaVideoItem from 'shared/components/MediaVideoItem';
import Loading from 'shared/components/loadings/Loading';
import MediaVideoUploader from 'shared/components/MediaVideoUploader/index';
import MediaAudioUploaderModal from 'shared/components/MediaAudioUploaderModal';

const VideoTab = ({ media, updateMovieStore, getMovie, movieId }) => {
  const [replaceState, setReplaceState] = useState({ show: false });
  const [mediaAudioSettings, setMediaAudioSettings] = useState({ show: false });
  const uploader = useSelector(state => state.uploader);
  const [isFetching, setIsFetching] = useState(false);
  const [audioTrackLang, setAudioTrackLang] = useState(allLanguages.find(i => i.code === 'hy'));
  const { enqueueSnackbar } = useSnackbar();

  const onItemRemove = async () => {
    try {
      await Api.delete(`movies/${media.id}/info`);
      getMovie();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFileSuccess = () => {
    getMovie();
    setTimeout(getMovie, 5000);
  };

  const onYoutubeLinkSubmit = async params => {
    try {
      setIsFetching(true);
      const body = { title: 'video', media_type: 'YOUTUBE', ...params };
      const res = await Api.post(`/movies/${media.id}/info`, body);
      const tempMedia = { ...media };
      tempMedia.item = res.data;
      updateMovieStore(tempMedia);
      setIsFetching(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setIsFetching(false);
    }
  };

  const createAndGetMediaItemId = async () => {
    try {
      const body = { title: 'video', media_type: 'NATIVE', audio_track_lang: audioTrackLang?.code };
      const endpoint = `/movies/${media.id}/info`;
      const res = await Api.post(endpoint, body);
      const tempMedia = { ...media };
      tempMedia.item = res.data;
      updateMovieStore(tempMedia);
      return res.data.id;
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      return null;
    }
  };

  const onHandleReplaceVideoClick = () => {
    if (media.item.replacement_status === 'PROGRESSING') {
      enqueueSnackbar(
        'Unable to perform media replacement as an ongoing replacement process is still in progress',
        { variant: 'error' },
      );
      return;
    }
    setReplaceState({ show: true, type: 'MOVIE', itemId: media.item.id, id: media.id });
  };

  const onClickOpenAudioMenu = () => {
    setMediaAudioSettings({ show: true, item: media.item });
  };

  if (!media) return <Loading className='mt-3' />;

  const progress = media.item && uploader[media.item.id];

  return (
    <div className='video-area mt-3'>
      {media?.item && media?.item?.status !== 'DELETED' ? (
        <MediaVideoItem
          video={{ ...media.item, movieId }}
          onItemRemove={onItemRemove}
          videoProgress={progress}
          showReplaceOption={true}
          disabled={!PermissionService.hasAccess('media_edit')}
          onClickReplaceVideo={onHandleReplaceVideoClick}
          onClickOpenAudioMenu={onClickOpenAudioMenu}
        />
      ) : (
        <MediaVideoUploader
          onYoutubeSubmit={onYoutubeLinkSubmit}
          isFetching={isFetching}
          onFileSuccess={onFileSuccess}
          showOptionsBeforeUpload={true}
          lang={audioTrackLang?.code}
          optionsState={{ audioTrackLang, setAudioTrackLang }}
          uploadOptions={{
            type: 'MOVIE',
            id: media.id,
            getItemId: createAndGetMediaItemId,
          }}
        />
      )}
      {replaceState.show && (
        <MediaVideoUploader
          isMediaReplace={true}
          showUploadOptionsInitial={true}
          onFileSuccess={onFileSuccess}
          lang={audioTrackLang?.code}
          optionsState={{ audioTrackLang, setAudioTrackLang }}
          onCloseSuccess={() => setReplaceState({ show: false })}
          onHandleConvertSuccess={() => setReplaceState({ show: false })}
          uploadOptions={{ ...replaceState }}
        />
      )}
      {mediaAudioSettings.show && (
        <MediaAudioUploaderModal
          movieId={movieId}
          item={mediaAudioSettings.item}
          onClose={() => {
            setMediaAudioSettings({ show: false });
            getMovie();
          }}
        />
      )}
    </div>
  );
};

export default VideoTab;

import React, { useState } from 'react';
import { useEffect } from 'react';
import CheckBox from 'shared/components/forms/CheckBox';
import RadioBox from 'shared/components/forms/RadioBox';
import Switcher from 'shared/components/forms/Switcher';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSelector } from 'react-redux';
import { onPlansSet, onProductsSet, setTotalValue } from 'app/Main/actions';
import { useDispatch } from 'react-redux';
import ReactSelect from 'shared/components/forms/ReactSelect';
import { sortDataWithKey } from 'utils/sortingHelper';
import { PermissionService } from 'services/PermissionService';

const days = [
  { name: '1 Day', key: '1' },
  { name: '2 Days', key: '2' },
  { name: '3 Days', key: '3' },
];

const avodTypes = [
  { name: 'Standard', key: 'standard' },
  { name: 'Special', key: 'special' },
];

const initialTypes = { AVOD: false, SVOD: false, TVOD: false, FVOD: false };

const Pricing = ({ onSuccess, monetization, endpoint, isOnModal, disableTVOD }) => {
  const productType = 'consumable';
  const dispatch = useDispatch();
  const plans = useSelector(state => state.plans?.content);
  const products = useSelector(state => state.products[productType]?.content);
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [paidTypes, setPaidTypes] = useState({ ...initialTypes });
  const [avodAds, setAvodAds] = useState([]);
  const actionsDisabled = !PermissionService.hasAccess('media_edit');

  const [avodForm, setAvodForm] = useState({
    type: 'standard',
    activeAD: null,
  });

  const [svodForm, setSvodForm] = useState({
    plans: [],
  });

  const [tvodForm, setTvodForm] = useState({
    oneTimeProduct: '',
    oneTimePurchase: false,
    rentalPrice: false,
    rentalPriceProduct: '',
    rentalPriceDay: '',
  });

  const getAvodAds = async () => {
    try {
      const { data } = await Api.get('/ad-configs');
      setAvodAds(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getPlans = async () => {
    try {
      const res = await Api.get('/subscription-plans', {
        params: { size: 1000, page: 0 },
      });
      res.data = sortDataWithKey(res.data);
      dispatch(onPlansSet({ content: res.data, total_elements: res.data.length }));
      dispatch(setTotalValue('all_plans', res.data.length));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getProducts = async () => {
    try {
      const res = await Api.get('/products', {
        params: {
          type: String(productType).toUpperCase(),
          size: 1000,
          page: 0,
        },
      });
      dispatch(onProductsSet({ [productType]: res.data }));
      dispatch(setTotalValue(`all_products_${productType}`, res.data.total_elements));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSetPaidTypes = types => {
    const res = { ...initialTypes };
    types.forEach(type => (res[type] = true));
    setPaidTypes(res);
  };

  const getAVODValue = () => {
    if (!paidTypes.AVOD) return undefined;
    if (avodForm.type === 'standard') return 0;
    return avodForm?.activeAD?.id;
  };

  const checkValidation = () => {
    let isValidated = true;

    if (paidTypes.AVOD && avodForm.type !== 'standard' && !avodForm?.activeAD) {
      isValidated = false;
      enqueueSnackbar('Please Select AVOD AD or disable it', { variant: 'error' });
    }

    if (paidTypes.SVOD && !svodForm.plans?.length) {
      isValidated = false;
      enqueueSnackbar('Please Select SVOD plans or disable it', { variant: 'error' });
    }

    if (
      paidTypes.TVOD &&
      (!tvodForm.rentalPrice ||
        (tvodForm.rentalPrice && (!tvodForm.rentalPriceDay || !tvodForm.rentalPriceProduct)))
    ) {
      isValidated = false;
      enqueueSnackbar('Please Select TVOD options or disable it', { variant: 'error' });
    }

    return isValidated;
  };

  const onSavePricing = async () => {
    try {
      const isValidated = checkValidation();
      if (!isValidated) return;
      setFetching(true);
      const tvodOptions = {
        in_app_product_id: tvodForm.rentalPriceProduct.id,
        days: tvodForm.rentalPriceDay?.key,
        resolution: 'SD',
      };
      const body = {
        avod: getAVODValue(),
        svod: paidTypes.SVOD ? svodForm.plans.map(i => i.id) : [],
        tvod: paidTypes.TVOD ? [tvodOptions] : [],
      };
      await Api.put(endpoint, paidTypes.FVOD ? { fvod: true } : body);
      onSuccess && (await onSuccess());
      enqueueSnackbar('Successfully done', { variant: 'success' });
      setFetching(false);
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSetAVODOptions = option => {
    setAvodForm({
      type: !option ? 'standard' : 'special',
      activeAD: avodAds.find(i => i.id === option),
    });
  };

  const onSetSVODOptions = options => {
    if (!options?.length) return;
    const activePlans = [...plans].filter(i => options.includes(i.id));
    setSvodForm({ plans: activePlans });
  };

  const onSetTVODOptions = options => {
    const option = options?.length ? options[0] : false;
    if (!option) return;
    setTvodForm({
      oneTimeProduct: '',
      oneTimePurchase: false,
      rentalPrice: !!option.in_app_product_id,
      rentalPriceProduct: products.find(i => i.id === option.in_app_product_id),
      rentalPriceDay: days.find(i => Number(i.key) === Number(option.days)),
    });
  };

  useEffect(() => {
    getAvodAds();
    getPlans();
    getProducts();
  }, []);

  useEffect(() => {
    const contentPaidTypes = Object.keys(monetization || {}).map(i => i.toUpperCase());
    onSetPaidTypes(contentPaidTypes);
  }, []);

  useEffect(() => {
    if (products) onSetTVODOptions(monetization?.tvod);
  }, [monetization, products]);

  useEffect(() => {
    if (plans) onSetSVODOptions(monetization?.svod);
  }, [monetization, plans]);

  useEffect(() => {
    if (avodAds) onSetAVODOptions(monetization?.avod);
  }, [monetization, avodAds]);

  return (
    <form className='d-flex flex-column h-100 justify-content-between pt-4 w-100'>
      {!isOnModal && (
        <div className='form-field-container d-flex justify-content-between border-bottom pb-3'>
          <label className='fw-500 mb-0 col p-0'>
            Content Access
            <p className='text-secondary small pr-2'>
              Choose to offer this video for free or custom access.
            </p>
          </label>
        </div>
      )}
      <div>
        <div>
          <div className='form-field-container overflow-initial d-flex justify-content-between border-bottom pb-3'>
            <label className='fw-500 mb-0 col p-0'>
              SVOD
              <p className='text-secondary small pr-2'>Subscription Video on Demand</p>
            </label>
            <div className='col-2'>
              <Switcher
                className='d-flex align-items-center'
                labelClass='text-sm ml-2'
                switchCheck={paidTypes.SVOD}
                onSwitchChange={() =>
                  setPaidTypes({ ...paidTypes, SVOD: !paidTypes.SVOD, FVOD: false })
                }
              />
            </div>
            <div className='col-6'>
              <ReactSelect
                value={svodForm.plans}
                onChange={value => setSvodForm({ ...svodForm, plans: value })}
                options={plans}
                className='mb-4'
                placeholder='Select Plans'
                getOptionValue={i => i.id}
                getOptionLabel={i => i.name}
                isMulti={true}
                disabled={!paidTypes.SVOD}
              />
            </div>
          </div>
        </div>
        <div>
          <div className='form-field-container overflow-initial d-flex justify-content-between border-bottom pb-3'>
            <label className='fw-500 mb-0 col p-0'>
              TVOD
              <p className='text-secondary small pr-2'>Transactional Video on Demand</p>
            </label>
            <div className='col-2'>
              <Switcher
                className='d-flex align-items-center'
                labelClass='text-sm ml-2'
                switchCheck={paidTypes.TVOD}
                disabled={paidTypes.AVOD || disableTVOD}
                onSwitchChange={() =>
                  setPaidTypes({ ...paidTypes, TVOD: !paidTypes.TVOD, FVOD: false })
                }
              />
            </div>
            <div className='col-6 pr-0'>
              {/* <CheckBox
                  name='one_time_purchase'
                  checked={tvodForm.oneTimePurchase}
                  onChange={b =>
                    setTvodForm({ ...tvodForm, oneTimePurchase: !tvodForm.oneTimePurchase })
                  }
                  label='One-time Purchase price'
                  subLabel='Mauris urna iaculis sem, id varius odio.'
                  labelClassName='is-small fw-500'
                  className='d-flex align-items-center'
                  disabled={!paidTypes.TVOD}
                />
                <ReactSelect
                  value={tvodForm.oneTimeProduct}
                  onChange={value => setTvodForm({ ...tvodForm, oneTimeProduct: value })}
                  options={products}
                  className='mb-4'
                  placeholder='Select Product'
                  getOptionValue={i => i.id}
                  getOptionLabel={i => i.name}
                  disabled={!paidTypes.TVOD || !tvodForm.oneTimePurchase}
                /> */}
              <CheckBox
                name='rental_price'
                checked={tvodForm.rentalPrice}
                onChange={b =>
                  setTvodForm({
                    ...tvodForm,
                    rentalPrice: !tvodForm.rentalPrice,
                  })
                }
                label='Rental Price'
                subLabel='ID varius odio elit ut massa.'
                labelClassName='is-small fw-500'
                className='d-flex align-items-center'
                disabled={!paidTypes.TVOD}
              />
              <div className='d-flex pr-2'>
                <ReactSelect
                  value={tvodForm.rentalPriceProduct}
                  onChange={value => setTvodForm({ ...tvodForm, rentalPriceProduct: value })}
                  options={products}
                  className='mb-4 w-100 col-8 p-0 mr-1'
                  placeholder='Product'
                  getOptionValue={i => i.id}
                  getOptionLabel={i => i.name}
                  menuPlacement='top'
                  disabled={!paidTypes.TVOD || !tvodForm.rentalPrice}
                />
                <ReactSelect
                  value={tvodForm.rentalPriceDay}
                  onChange={value => setTvodForm({ ...tvodForm, rentalPriceDay: value })}
                  options={days}
                  className='mb-4 w-100 col-4 p-0 ml-1'
                  placeholder='Day'
                  getOptionValue={i => i.key}
                  getOptionLabel={i => i.name}
                  menuPlacement='top'
                  disabled={!paidTypes.TVOD || !tvodForm.rentalPrice}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='form-field-container overflow-initial d-flex justify-content-between border-bottom pb-3'>
          <label className='fw-500 mb-0 col p-0'>
            AVOD
            <p className='text-secondary small pr-2'>Advertising Video on Demand</p>
          </label>
          <div className='col-2'>
            <Switcher
              className='d-flex align-items-center'
              labelClass='text-sm ml-2'
              switchCheck={paidTypes.AVOD}
              disabled={paidTypes.TVOD && !paidTypes.AVOD}
              onSwitchChange={() =>
                setPaidTypes({ ...paidTypes, AVOD: !paidTypes.AVOD, FVOD: false })
              }
            />
          </div>
          <div className='col-6'>
            <div className='col-9 d-flex align-items-center justify-content-between'>
              {avodTypes.map(item => {
                return (
                  <RadioBox
                    key={item.key}
                    name='period'
                    checked={avodForm.type === item.key}
                    onChange={() => setAvodForm({ ...avodForm, type: item.key })}
                    label={item.name}
                    labelClass='text-nowrap pl-1'
                    className='mb-3 col-4 p-0 pl-2'
                    disabled={!paidTypes.AVOD}
                    required
                  />
                );
              })}
            </div>
            <ReactSelect
              placeholder='Select special type'
              disabled={!paidTypes.AVOD || avodForm.type !== 'special'}
              options={avodAds}
              value={avodForm.activeAD}
              className='mb-4'
              getOptionValue={i => i.id}
              getOptionLabel={i => i.name}
              onChange={value => setAvodForm({ ...avodForm, activeAD: value })}
            />
          </div>
        </div>
        <div className='form-field-container overflow-initial d-flex justify-content-between border-bottom pb-3'>
          <label className='fw-500 mb-0 col p-0'>
            FVOD
            <p className='text-secondary small pr-2'>Free Video on Demand</p>
          </label>
          <div className='col-2'>
            <Switcher
              className='d-flex align-items-center'
              labelClass='text-sm ml-2'
              switchCheck={paidTypes.FVOD}
              // disabled={paidTypes.TVOD && !paidTypes.AVOD}
              onSwitchChange={() => setPaidTypes({ ...initialTypes, FVOD: !paidTypes.FVOD })}
            />
          </div>
          <div className='col-6' />
        </div>
      </div>
      <div className='text-right mt-2'>
        <button
          disabled={fetching || actionsDisabled}
          type='button'
          onClick={onSavePricing}
          className='btn btn-sm btn-outline-primary mnw-100 py-1 text-uppercase weight-400 fz-12'
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default Pricing;
